
document.addEventListener('DOMContentLoaded', function() {

  var body = document.body
  var toggleBtn = document.querySelector('.sw-header__menu-button')
  var overlay = document.querySelector('.sw-header__overlay')
  var menuItems = document.querySelectorAll('.sw-menu__primary > li')
  var searchBtn = document.querySelector('.sw-header__search-button')
  var searchForm = document.querySelector('.sw-header__search')
  var searchResults = document.querySelector('.pp-search-results')
  var searchResultsFilterMenu = document.querySelector('.pp-search-results .pp-search-results__filter-menu')
  var searchResultsFilter = document.querySelector('.pp-search-results .pp-search-results__filters')

  if(searchBtn && searchForm) {
    searchBtn.addEventListener('click', function() {
      if (searchResults && searchResults.classList.contains('filter--open')) {
        searchResults.classList.remove('filter--open')
      }
      searchForm.classList.toggle('open')
      body.classList.toggle('search-open')
      searchForm.querySelector('.sw-search__input').focus()
    })
  }

  if(searchResultsFilterMenu && searchForm) {
    searchResultsFilterMenu.addEventListener('click', function() {
      if (searchForm.classList.contains('open')) {
        searchForm.classList.remove('open')
        body.classList.remove('search-open')
      }
    })
  }

  if(toggleBtn) {
    toggleBtn.addEventListener('click', function() {
      body.classList.add('menu-open')
    })
  }

  if(overlay) {
    overlay.addEventListener('click', function () {
      body.classList.remove('menu-open')
    })
  }

  if(menuItems) {
    menuItems.forEach(function(item) {
      item.addEventListener('click', function(e) {
        if(window.innerWidth < 912) {
          e.stopPropagation()
          var itemIsOpen = e.target.classList.contains('open')
          var activeItem = document.querySelector('.open')
          if(activeItem) activeItem.classList.remove('open')
          if(!itemIsOpen) {
            e.target.closest('li').classList.add('open')
          } 
        }
      })
    })
  }
})